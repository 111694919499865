import axios from "../../../utility/api"

const userDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    name: item ? item.name : "",
    number: item ? item.number : "",
    card_number: item ? item.card_number : "",
    ccms: (item && item.ccms) ? item.ccms : "",
    name_ccms: (item && item.name_ccms) ? item.name_ccms : "",
    login: item ? item.login : "",
    building: item ? item.building : "",
    floor: item ? item.floor : "",
    project: item ? item.project : "",
    email: item ? item.email : "",
    card: item ? item.card : "",
    pin: item ? item.pin : "",
    obs: (item && item.obs) ? item.obs : "",
    status: "active",
    validUntil: (item && item.valid_until) ? item.valid_until : "",
    userType: (item && item.locker_user_type) ? item.locker_user_type.name : "",
    lockers: item && item.lockers.map((item2, i2) =>  {
      return {
        id: item2.id,
        number: item2.locker_metra_id,
        place: {
          id: item2.place.id,
          name: item2.place.name,
        },
      }
    }),
    assets: item ? item.assets : [],
    groups: item ? item.groups : []
  }
}

const userKpis = (item = false) => {
  return {
    recentActions: item ? item.recentActions : [],
    reports: item ? item.reports : [],
    timeSpentWithDoorOpen: {
      last1Year: item ? item.timeSpentWithDoorOpen.last1Year : 0,
      last30Days: item ? item.timeSpentWithDoorOpen.last30Days : 0,
    },
    totalDoorOpenings: {
      last1Year: item ? item.totalDoorOpenings.last1Year : 0,
      last15Days: item ? item.totalDoorOpenings.last15Days : 0,
      last30Days: item ? item.totalDoorOpenings.last30Days : 0,
    },
    totalReports: {
      last1Year: item ? item.totalReports.last1Year : 0,
      last15Days: item ? item.totalReports.last15Days : 0,
      last30Days: item ? item.totalReports.last30Days : 0,
    },
  }
}

export const getUsers = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {

    if(reset){
      dispatch({
        type: "GET_USERS_LIST_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/users')
    .then(response => {
      if (response.data.ResultCode===1) {
        let userList = response.data.Data.map((item, i) => {
          return userDetails(item)
        })
        dispatch({
          type: "GET_USERS_LIST_SUCCESS",
          payload: userList
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getUserDetails = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_USER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_USER_DETAILS_SUCCESS",
        payload: userDetails()
      });
      return;
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/user/'+id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(res.data.Data)
        });
      } else {
        reject()
        throw(res);
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
})

export const setUserDetails = (token,user) => (dispatch) =>
new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  if (user.id === 0) {
    axios.post('api/julian/user', {
        name: user.name,
        //number: user.number,
        //cardNumber: user.card_number,
        //login: user.login,
        ccms: user.ccms,
        card: user.card,
        //nameCcms: user.name_ccms,
        //validUntil: user.valid_until,
        building: user.building,
        floor: user.floor,
        company: user.project,
        //email: user.email,
        //lockerUserType: user.lockerUserType,
        obs: user.obs
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
  } else {
    axios.put('api/julian/user', {
        id: user.id,
        name: user.name,
        //number: user.number,
        //cardNumber: user.card_number,
        //login: user.login,
        ccms: user.ccms,
        card: user.card,
        //nameCcms: user.name_ccms,
        validUntil: user.valid_until,
        building: user.building,
        floor: user.floor,
        company: user.project,
        //email: user.email,
        //lockerUserType: user.lockerUserType,
        obs: user.obs
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
  }
})


export const setUserCard = (token,user) => (dispatch) =>
new Promise(function(resolve, reject){
    if (user.id === 0) {
      reject()
      throw("error");
    } else {
      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.put('api/julian/user', {
          id: user.id,
          card: user.card
      })
      .then(res => {
        if (res.data.ResultCode===1) {
          dispatch({
            type: "GET_USER_DETAILS_SUCCESS",
            payload: userDetails(res.data.Data)
          });
          resolve()
        } else {
          reject()
          throw(res);
        }
      })
      .catch(err => {
        reject()
        console.log(err)
      })
    }
})

export const setUserPin = (token,user) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (user.id === 0) {
      return
    } else {
      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.put('api/julian/user', {
          id: user.id,
          pin: user.pin
      })
      .then(res => {
        if (res.data.ResultCode===1) {
          dispatch({
            type: "GET_USER_DETAILS_SUCCESS",
            payload: userDetails(res.data.Data)
          });
          resolve()
        } else {
          reject()
          throw(res);
        }
      })
      .catch(err => {
        reject()
        console.log(err)
      })
    }
})

export const getLockers = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
      if (reset) {
        dispatch({
          type: "GET_USER_PLACES_LOCKERS",
          payload: false
        })
      }

      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.get('api/julian/lockers')
      .then(res => {
        if (res.data.ResultCode===1) {
          let lockers = res.data.Data && Object.keys(res.data.Data).reduce(function (r, a) {
            r[a] = res.data.Data[a]
            return r
          }, {})

          dispatch({
            type: "GET_USER_PLACES_LOCKERS",
            payload: lockers
          })
          resolve()
        }else{
          reject()
          throw(res)
        }
      }).catch(err => {
        console.log(err)
        reject()
      })
})

export const getUserKpis = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_USER_KPIS_SUCCESS",
        payload: false
      });
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/dashboard/user/' + id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_USER_KPIS_SUCCESS",
          payload: userKpis(res.data.Data)
        });
        resolve()
      } else {
        reject()
        throw(res)
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
})

export const deleteUser = (token, user) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.delete('api/julian/user/' + user.id).then(response => {
      if (response.data.ResultCode===1) {
        let userList = response.data.Data.map((item, i) => {
          return userDetails(item)
        })
        dispatch({
          type: "GET_USERS_LIST_SUCCESS",
          payload: userList
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
})
