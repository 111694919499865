import React from "react"
import logo from "../../../assets/img/logo/logo.png"
import "../../../assets/scss/components/app-loader.scss"
import { useSelector } from 'react-redux'

function SpinnerComponent() {
  const theme = useSelector((state) => state.customizer.customizer.theme)
  return (
    <div className={"spinner-container shadow "+(theme === 'dark' ? 'dark-spinner' : 'light-spinner')}>
      <div className="spinner-logo-wrapper">
        <img className="fallback-logo" src={logo} alt="logo" />
      </div>
      <div className="spinner-fx-wrapper">
        <div className="loader"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
