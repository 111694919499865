const locationsReducer = (state = { places: [],
                                    lockersByPlace: {Lockers: []},
                                    selectedPlace: {},
                                    analytics: { users: [],
                                                 lockers: [],
                                                 doorOpeningsDistribution: [],
                                                 doorOpeningsPerUser: [] },
                                    lockersBySize: [],
                                    locationConfig: [] }, action) => {
  switch (action.type) {
    case "GET_PLACES_SUCCESS":
      return { ...state, places: action.payload }
    case "GET_LOCKERS_BY_PLACE_SUCCESS":
      return { ...state, lockersByPlace: action.payload }
    case "SET_SELECTED_PLACE":
      return { ...state, selectedPlace: action.payload }
    case "GET_PLACE_DETAILS_SUCCESS":
      return { ...state, selectedPlace: action.payload }
    case "GET_PLACE_ANALYTICS_SUCCESS":
      return { ...state, analytics: action.payload }
    case "UPDATE_LOCKER_STATUS_SUCCESS":
      return { ...state, lockersByPlace: {
        Logs: [
                {
                  action_id: action.payload.actionId,
                  loker_id: action.payload.lokerId,
                  locker_metra_id: action.payload.lockerMetraId,
                  username: ''
                },
                ...state.lockersByPlace.Logs.slice(0, -1)
        ],
        Lockers: state.lockersByPlace.Lockers.map(item => item.id == action.payload.lokerId ?
          { ...item, locker_metra_status: action.payload.actionId } : item )} }
    case "GET_LOCKERS_BY_SIZE_SUCCESS":
      return { ...state, lockersBySize: action.payload }
    case "GET_LOCATION_CONFIG_SUCCESS":
     return { ...state, locationConfig: action.payload }
    default:
      return state
  }
}

export default locationsReducer
