import React from "react"
import {  Navbar,
          Button,
          Input,
          InputGroup,
          InputGroupAddon,
          Row} from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import { withTranslation } from 'react-i18next';
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { logoutWithApi } from "../../../redux/actions/auth/loginActions"
import { connect } from "react-redux"
import ToggleDarkMode from '../../../components/ToggleDarkMode'
import {  Monitor,
          X,
          Package} from "react-feather"
import { history } from "../../../history"
import ScanOrder from "../../../components/ScanOrder"
import SpeechRecognition from "../../../components/SpeechRecognition"
import Notifications from "./Notifications"
import NavbarLanguage from "./NavbarLanguage"

class ThemeNavbar extends React.Component {
  state = {
    searchInput: '',
    isSearchInputVisible: false
  }
  handleLogout = () => {
    this.props.logoutWithApi()
  }

  handleSearchIconClick = () => {
    this.setState({isSearchInputVisible: !this.state.isSearchInputVisible})
  }

  searchOrder = () => {
    history.push(`/orders/details/${this.state.searchInput}`)
    this.setState({isSearchInputVisible: !this.state.isSearchInputVisible})
  }

  render(){
    const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": this.props.navbarColor === "default" || !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" && this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" && this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" && this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" && this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" && this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" && this.props.navbarType !== "static",
              "d-none": this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" && !this.props.horizontal) || (!navbarTypes.includes(this.props.navbarType) && !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top": this.props.navbarType === "sticky" || this.props.horizontal,
              "scrolling": this.props.horizontal && this.props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              { this.state.isSearchInputVisible &&
                <Row className="navbar-search-input-wrapper">
                  <X size={22} onClick={this.handleSearchIconClick} className="navbar-search-icon"/>
                    <InputGroup className="navbar-search-input">
                      <Input
                        type="text"
                        value={this.state.searchInput}
                        onChange={(e) => {
                          this.setState({searchInput: e.target.value})
                        }}
                        id="searchInput"
                      />
                    <InputGroupAddon addonType="append">
                        <Button onClick={this.searchOrder}>
                          <Package size={18} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                </Row>
              }
              { !this.state.isSearchInputVisible &&
                <div
                  className="navbar-collapse d-flex justify-content-between align-items-center"
                  id="navbar-mobile">

                  <div className="nav navbar-nav bookmark-wrapper">
                    <NavbarBookmarks
                      sidebarVisibility={this.props.sidebarVisibility}
                      handleAppOverlay={this.props.handleAppOverlay}
                      handleSearchIconClick={this.handleSearchIconClick}
                    />
                  </div>

                  {this.props.horizontal ? (
                    <div className="logo d-flex align-items-center">
                      <div className="brand-logo mr-50"></div>
                      <h2 className="text-primary brand-text mb-0">&nbsp;</h2>
                    </div>
                  ) : null}

                    <ul className="nav navbar-nav navbar-nav-user float-right">
                      {this.props.auth.login.username === "demo" && <SpeechRecognition t={this.props.t}/>}
                      {this.props.deviceWidth >= 860 &&
                        <div onClick={() => window.open('/monitoring', '_blank')} className="navbar-icon-wrapper cursor-pointer">
                          <Monitor size={22} className="navbar-icon"/>
                        </div>
                      }
                      <ScanOrder />
                      <Notifications />
                      <ToggleDarkMode
                        activeMode={this.props.activeMode}
                        changeMode={this.props.changeMode}/>
                      <NavbarLanguage
                        i18n={this.props.i18n}/>
                      <NavbarUser
                        i18n={this.props.i18n}
                        handleAppOverlay={this.props.handleAppOverlay}
                        changeCurrentLang={this.props.changeCurrentLang}
                        auth={this.props.auth.login}
                        userImg={ userImg }
                        logout={this.handleLogout}
                        activeMode={this.props.activeMode}
                        changeMode={this.props.changeMode}/>
                    </ul>
                </div>
              }
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    )
  }

}


export default connect(null, { logoutWithApi })(withTranslation('common')(ThemeNavbar))
